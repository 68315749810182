import React from 'react';
import './Header.scss';
import { scroller, Element } from 'react-scroll';

import img1 from './img/landscaping/overhead.jpg';
import img2 from './img/landscaping/fire_pit.jpg';
import img3 from './img/landscaping/water_feature_1.jpg';
import img4 from './img/landscaping/water_feature_2.jpg';
import servicemap from './img/servicemap.jpg';

const Home = () => (
  <div className="App">
      <div class="container_fluid" id="carousel">
        <div id="landscapingphotos" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#landscapingphotos" data-slide-to="0" class="active"></li>
              <li data-target="#landscapingphotos" data-slide-to="1"></li>
              <li data-target="#landscapingphotos" data-slide-to="2"></li>
              <li data-target="#landscapingphotos" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src={img1} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={img2} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={img3} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={img4} class="d-block w-100" alt="..." />
              </div>
            </div>
            <a class="carousel-control-prev" href="#landscapingphotos" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#landscapingphotos" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
          <section id="tagline">
            <h4>
                Whether you are looking to create a professional and manicured look for your home, spruce up your yard, or install that new patio, you will want to put the Kestrel Design team to work for you.
            </h4>
            <p>
                With a combined <b>60 years</b> of award-winning work, <span id="name">Kestrel Design</span> has the experience to service all your construction and maintenance needs.
            </p>
          </section>
          <section id="services">
            <Element name="servicesScroll" />
            <h1 class="text-center">Services</h1>
            <div class="row" >
              <div class="col">
                <h2 class="service-title">Landscape Design</h2>
                <ul>
                  <li>New Construction</li>
                  <li>Renovations</li>
                  <li>Flower Gardens and Pots</li>
                  <li>Backyard Retreats</li>
                </ul>
              </div>
              <div class="col">
                <h2 class="service-title">Installations</h2>
                <ul>
                  <li>Sod, Seed, Trees Shrubs, and Flower
                      Gardens</li>
                  <li>Brick Pavers Driveways, Patios, and
                      Walks</li>
                  <li>Ponds and Water Features</li>
                </ul>
              </div>
              <div class="col">
                <h2 class="service-title">Maintenance</h2>
                <ul>
                  <li>Mowing</li>
                  <li>Spring and Fall Clean-Ups</li>
                  <li>Pruning</li>
                  <li>Perennial, Annual, and Rose Gardens</li>
                  <li>Flowerpots and Brick</li>
                </ul>
              </div>
              <div class="col">
                <h2 class="service-title">Pergolas, Trellises, Patios, and Stone Work</h2>
                <ul>
                  <li>Fireplaces</li>
                  <li>Fire Features</li>
                  <li>Grilling Islands and Outdoor Kitchens</li>
                  <li>Pergolas and Trellises</li>
                </ul>
              </div>
            </div>
          </section>
          <div id="servicemap">
            <h1 class="text-center">Servicing the Western Suburbs of Chicago</h1>
          </div>
          <div id="contact-us">
            <Element name="contactScroll" />
            <h1 className="text-center">Call or email us today for your home landscape consultation</h1>
            <div class="clearfix">
              <p class="contact text-center">Tom Joerger: <a href="tel:16308018541">630-801-8541</a>, <a href="mailto:tjoerger@kestreldesign.net">tjoerger@kestreldesign.net</a></p>
              <p class="contact text-center">Julio Hurtado: <a href="tel:17082688937">708-268-8937</a>, <a href="mailto:jhurtado@kestreldesign.net">jhurtado@kestreldesign.net</a></p>
            </div>   
          </div> 
    </div>

  </div>
);

export default Home;
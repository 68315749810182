import React from 'react';
import { scroller } from 'react-scroll';
import './Header.scss';
import cutout from './img/KDwebTopper.png';
import cutout_stacked from './img/KDwebTopper_stacked.png';
import { Link } from "react-router-dom";

const defaultWidth = 20;

const scrollTo = (toScroll) => {
  scroller.scrollTo(toScroll, {
    duration: 800,
    smooth: 'easeInOutQuart',
    isDynamic: true
  });
}

const Header = () => (
  <header>
    <div class="container-fluid" id="topbar">
      <div>
        <nav class="navbar navbar-expand-lg navbar-dark" style={{backgroundColor: "#634632", paddingBottom: "0"}}>
          <Link id="logo-nav-link" class="nav-link navbar-brand" to="/">
            <img src={cutout_stacked} class="d-inline-block align-top" alt="" />
          </Link>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".collapse">
              <span class="navbar-toggler-icon"></span>
          </button>
          <div class="navbar-collapse collapse">
            <ul class="navbar-nav mr-auto text-center">
              <li class="nav-item">
                <a class="nav-link" onClick={event => scrollTo('servicesScroll')}>Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" onClick={event => scrollTo('contactScroll')}>Contact Us</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
);

export default Header;
import React from 'react';
import Header from './Header';
import Home from './Home';
import Services from './Services';
import './App.scss';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Route exact path="/" component={Home} />
      </div>
      <div class="container text-center">
        <footer>
            &copy; Copyright {new Date().getFullYear()} Kestrel Design
        </footer>
      </div> 
    </Router>
  );
}

export default App;
